/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  justify?: "start" | "center" | "end" | "between" | "around";
  inline?: boolean;
  align?: "start" | "center" | "end";
  column?: boolean;
  wrap?: boolean;
  className?: string;
}

const Flex: React.FC<Props> = ({
  justify,
  align,
  inline,
  column,
  wrap,
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        {
          "d-flex": !inline,
          "d-inline-flex": inline,
          [`justify-content-${justify}`]: justify,
          [`align-items-${align}`]: align,
          "flex-column": column,
          "flex-wrap": wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Flex.defaultProps = {
  column: false,
  inline: false,
  wrap: false,
};

export default Flex;
